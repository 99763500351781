import React from "react";
import "./LazyImg.scss";
import newlogo from "../../assets/Logo/newlogo.png";

export default function LazyImg() {
  return (
    <div className="LazyImg">
      <img src={newlogo} alt="loading..." />
    </div>
  );
}
